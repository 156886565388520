<template>
  <div>
    <div class="barnner">
      <img src="../../../static/newImage/development_title.png" width="880px" />
    </div>
    <div class="development_map">
      <div class="development_map_img">
        <img
          src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-26/87d98c091c30433bbcb547c8671632bd.png"
          width="880px" />
      </div>
      <div class="development_map_choose"></div>
      <div class="development_map_choose_img">
        <img src="../../../static/newImage/development_choose.png" width="46px" />
      </div>
      <div class="development_map_line">
        <div class="development_map_line_1"></div>
        <div class="development_map_line_2"></div>
        <div class="development_map_line_top" style="z-index: 101;width: 880px;height: 375px;"></div>
        <img class="development_map_line_top"
          src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-26/af784f6755914294bd4feee3a9967616.png"
          width="880px" />
        <div class="development_map_line_container1" v-show="playAm">
          <div class="earth-container">
            <div class="card card1"></div>
            <div class="card card2"></div>
            <div class="card card3"></div>
            <div class="card card4"></div>
            <div class="card card5"></div>
            <div class="card card6"></div>
            <div class="card card7"></div>
            <div class="card card8"></div>
            <div class="card card9"></div>
            <div class="card card10"></div>
          </div>
        </div>
        <div class="development_map_line_container1" v-show="!playAm">
          <div class="earth-container1">
            <div class="card card1" @click="roundMap(0)" :class="playAmIndex == 0 ? 'card1Active' : ''"></div>
            <div class="card card2" @click="roundMap(1)" :class="playAmIndex == 1 ? 'card2Active' : ''"></div>
            <div class="card card3" @click="roundMap(2)" :class="playAmIndex == 2 ? 'card3Active' : ''"></div>
            <div class="card card4" @click="roundMap(3)" :class="playAmIndex == 3 ? 'card4Active' : ''"></div>
            <div class="card card5" @click="roundMap(4)" :class="playAmIndex == 4 ? 'card5Active' : ''"></div>
            <div class="card card6" @click="roundMap(5)" :class="playAmIndex == 5 ? 'card6Active' : ''"></div>
            <div class="card card7" @click="roundMap(6)" :class="playAmIndex == 6 ? 'card7Active' : ''"></div>
            <div class="card card8" @click="roundMap(7)" :class="playAmIndex == 7 ? 'card8Active' : ''"></div>
            <div class="card card9" @click="roundMap(8)" :class="playAmIndex == 8 ? 'card9Active' : ''"></div>
            <div class="card card10" @click="roundMap(9)" :class="playAmIndex == 9 ? 'card10Active' : ''"></div>
            <div class="card card11" @click="roundMap(9)" :class="playAmIndex == 9 ? 'card10Active' : ''"></div>
          </div>
        </div>
        <div class="development_map_text" v-show="!playAm">
          <div @click="roundMap(index)" :class="'development_map_text development_map_text_' + index"
            v-for="(item, index) in (list.length + 1)" :key="index">{{ listName[index] }}</div>
        </div>
      </div>
    </div>
    <div
      :style="'background-image: url(' + list[developmentIndex].bgImage + ');background-repeat: no-repeat;background-size: 100%;'">
      <div class="development_bg" :style="'background: url(' + list[developmentIndex].image + ');'">
        <div class="development_bg_title">{{ listName1[developmentIndex] }}</div>
      </div>
      <div class="development_intro">
        <div class="development_intro_title" v-show="list[developmentIndex].content != '板块正在建设中……'">企业简介</div>
        <div class="development_intro_content" v-show="list[developmentIndex].content != '板块正在建设中……'">
          {{ list[developmentIndex].content }}
        </div>
        <div
          :style="developmentIndex == 6 || developmentIndex == 0 || developmentIndex == 9 ? 'background-image: url(' + 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/8fd87bbdb15448b9be66938c9ad792da.png' + ');background-repeat: no-repeat;background-size: 100%;margin-top: 100px;position: relative' : ''">
          <div v-show="developmentIndex == 9 || developmentIndex == 0"
            style="padding: 150px 0 100px 0;z-index: 2;position: relative;">
            <div class="development_news_bg">
              <el-carousel height="520px" indicator-position="none" ref="newsImgCarousel" arrow="never"
                @change="changeNewsImgCarousel" class="newsImgCarousel">
                <el-carousel-item v-for="(item) in newsList" :key="item.newsId + 'newsImg'">
                  <div class="newsImgCarousel_black" @click="toDetails(item, 22)"></div>
                  <img :src="item.newsCover" width="100%" @click="toDetails(item, 22)" />
                </el-carousel-item>
              </el-carousel>
              <div class="development_news_bg_bottom">
                <div class="development_news_bg_bottom_title" v-if="newsList.length"
                  @click="toDetails(newsList[newsImageIndex], 22)">
                  {{ newsList[newsImageIndex].newsTitle }}</div>
                <div style="margin-bottom: 25px;">
                  <div :class="newsImageIndex == index
          ? 'development_news_bg_bottom_Index active'
          : 'development_news_bg_bottom_Index'
          " v-for="(i, index) in newsList" @click="changeNewsImageIndex(index)" :key="index + 'newsImg1'">
                    {{ index + 1 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="development_more">
              <div class="development_more_btn" @click="toHomePath()">点击进入官网 ></div>
            </div>
            <div class="home_first_about">
              <div class="home_first_about_img">
                <div class="swiper-container swiper-container1">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in newsList1" :key="item.id"
                      style="height: 200px;overflow: hidden;">
                      <img class="home_all_module_img" :src="item.newsCover" style="width: 416px;transform: scale(1.4);"
                        @click="toDetails(item)" />
                    </div>
                  </div>
                  <div class="swiper-pagination swiper-pagination1"></div>
                </div>
                <div class="home_first_about_img_list" v-if="newsList1.length">
                  <div class="home_first_about_img_list_name moveTop" v-for="item in newsList1" :key="item.newsId"
                    @click="toDetails(item)">
                    {{ item.newsTitle }}
                  </div>
                </div>
              </div>
              <div class="home_first_about_img">
                <div class="swiper-container swiper-container2">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in newsList2" :key="item.id"
                      style="height: 200px;overflow: hidden;">
                      <img class="home_all_module_img" :src="item.newsCover" style="width: 416px;transform: scale(1.4);"
                        @click="toDetails(item)" />
                    </div>
                  </div>
                  <div class="swiper-pagination swiper-pagination2"></div>
                </div>
                <div class="home_first_about_img_list" v-if="newsList2.length">
                  <div class="home_first_about_img_list_name moveTop" v-for="item in newsList2" :key="item.newsId"
                    @click="toDetails(item)">
                    {{ item.newsTitle }}
                  </div>
                </div>
              </div>
              <div class="home_first_about_img">
                <div class="swiper-container swiper-container3">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in newsList3" :key="item.id"
                      style="height: 200px;overflow: hidden;">
                      <img class="home_all_module_img" :src="item.newsCover" style="width: 416px;transform: scale(1.4);"
                        @click="toDetails(item)" />
                    </div>
                  </div>
                  <div class="swiper-pagination swiper-pagination3"></div>
                </div>
                <div class="home_first_about_img_list" v-if="newsList3.length">
                  <div class="home_first_about_img_list_name moveTop" v-for="item in newsList3" :key="item.newsId"
                    @click="toDetails(item)">
                    {{ item.newsTitle }}
                  </div>
                </div>
              </div>
            </div>
            <div class="home_first_about">
              <div class="home_first_about_img">
                <div class="swiper-container swiper-container4">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in newsList4" :key="item.id"
                      style="height: 200px;overflow: hidden;">
                      <img class="home_all_module_img" :src="item.newsCover" style="width: 416px;transform: scale(1.4);"
                        @click="toDetails(item)" />
                    </div>
                  </div>
                  <div class="swiper-pagination swiper-pagination4"></div>
                </div>
                <div class="home_first_about_img_list" v-if="newsList4.length">
                  <div class="home_first_about_img_list_name moveTop" v-for="item in newsList4" :key="item.newsId"
                    @click="toDetails(item)">
                    {{ item.newsTitle }}
                  </div>
                </div>
              </div>
              <div class="home_first_about_img">
                <div class="swiper-container swiper-container5">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in newsList5" :key="item.id"
                      style="height: 200px;overflow: hidden;">
                      <img class="home_all_module_img" :src="item.newsCover" style="width: 416px;transform: scale(1.4);"
                        @click="toDetails(item)" />
                    </div>
                  </div>
                  <div class="swiper-pagination swiper-pagination5"></div>
                </div>
                <div class="home_first_about_img_list" v-if="newsList5.length">
                  <div class="home_first_about_img_list_name moveTop" v-for="item in newsList5" :key="item.newsId"
                    @click="toDetails(item)">
                    {{ item.newsTitle }}
                  </div>
                </div>
              </div>
              <div class="home_first_about_img">
                <div class="swiper-container swiper-container6">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in newsList6" :key="item.id"
                      style="height: 200px;overflow: hidden;">
                      <img class="home_all_module_img" :src="item.newsCover" style="width: 416px;transform: scale(1.4);"
                        @click="toDetails(item)" />
                    </div>
                  </div>
                  <div class="swiper-pagination swiper-pagination6"></div>
                </div>
                <div class="home_first_about_img_list" v-if="newsList6.length">
                  <div class="home_first_about_img_list_name moveTop" v-for="item in newsList6" :key="item.newsId"
                    @click="toDetails(item)">
                    {{ item.newsTitle }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="developmentIndex == 6">
            <div class="development_more">
              <div class="development_more_btn" @click="toHomePath()">点击进入官网 ></div>
            </div>
          </div>
          <div v-show="developmentIndex != 6 && developmentIndex != 0 && developmentIndex != 9">
            <div class="development_more">
              <div class="development_more_btn" style="background-color: #ffffffb3;color: #000000b3;">正在建设中</div>
            </div>
          </div>
          <img v-show="developmentIndex == 9 || developmentIndex == 0" style="position: absolute;left: 0;bottom: 0;"
            src="../../../static/newImage/development_bottom.png" width="100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import { Swiper } from "swiper";
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "industrial_development",

  data() {
    return {
      listName: ['中峪农业', '中峪交易市场', '中峪学校', '金生投资', '中峪商贸', '养老板块', '养老板块', '麒典信息科技', '医院板块', '制药板块', '中峪数交'],
      listName1: ['浙江中峪农业发展有限责任公司', '浙江中峪日用品交易市场有限公司', '绍兴中峪职业技能培训学校有限责任公司', '丽水金生投资管理有限责任公司', '四川中峪商贸服务有限公司', '养老板块', '浙江麒典信息科技有限公司', '医院板块', '制药板块', '四川中峪拍卖交易服务有限责任公司'],
      list: [
        {
          id: "6",
          title: "中峪农业",
          content:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。 中峪农业将始终秉承“传承经典、创新发展”的理念，不断提升道地药材的品质和效益。我们将积极拓展国际市场，将中华传统药材文化推向世界舞台，让更多人了解和受益于中医药的神奇魅力。",
          image: require('../../../static/newImage/yaocai.png'),
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/6c781e2a36874a62994be211556d1bed.png"
        },
        {
          id: "1",
          title: "中峪交易市场",
          content:
            "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、资产数字化运作、仓储物流、资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+金融服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为中峪道地药材产业链发展建立资产数字化交易平台，为线下实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。由交易市场和中峪拍交所赋能，成功开启道地药材线上线下创新融合，从中药材种子种苗溯源、带量采购、中草药制剂研发到医院养老产业端口输出，助推集团各个产业链的良性闭环。",
          image: require('../../../static/newImage/jiaoyi.jpg'),
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/7b73bf83aade48c6a16b41aea36f4f1f.png"
        },
        {
          id: "3",
          title: "中峪学校",
          content:
            "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
          image: require('../../../static/newImage/xuexiao.jpg'),
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/60920e9d33eb48709bd3965bda3e200d.png"
        },
        {
          id: "4",
          title: "金生投资",
          content:
            "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
          image: require('../../../static/newImage/touzi.jpg'),
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/12bac25080414af1b2f8020069ca18f3.png"
        },
        {
          id: "5",
          title: "中峪商贸",
          content:
            "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流量，协同中峪职业培训学校共同孵化输送业务体系人才，为地方解决就业、创业而努力。",
          image: require('../../../static/newImage/shangmao.jpg'),
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/2fdee550da6645c7bd9fa49884f36cfe.png"
        },
        {
          id: "10",
          title: "养老板块",
          content: "板块正在建设中……",
          image: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/30c0338582b04652be40f3cad042c7df养老w.jpg",
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/f1ce5968c16345249277200cfb76e1cf.png"
        },
        {
          id: "7",
          title: "麒典信息科技",
          content:
            "麒典信息科技是一家集大数据服务、核心技术研发、技术咨询服务、区块链技术应用、软件开发等科技研发公司，为集团产业链数字化运作提供强大技术输出，肩负集团产业链大数据信息储存输送和科技数字化运作重任，同时为助力地方产业数字化发展提供强大技术支撑，承载银发经济链产品研发和制造。",
          image: require('../../../static/newImage/xinxi.jpg'),
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/e635859048e34331ad5a0deb58af7644.png"
        },
        {
          id: "8",
          title: "医院板块",
          content: "板块正在建设中……",
          image: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/ca8e0b6b57b342e7a253c538e45f929d医院.jpg",
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/c7d62de6c39a47349873a2d611ef5288.png"
        },
        {
          id: "9",
          title: "制药板块",
          content:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司。为传承中医药文化，促进中医药特色发展，提升中医药产业水平做出积极贡献，是中峪道地药材的制药基地，是中峪医院的药材基地，为中峪康养产业提供优质药食同源。",
          image: require('../../../static/newImage/yiyuan.jpg'),
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/f92e4e997a6847508b0588464f48bcbb.png"
        },
        {
          id: "2",
          title: "中峪数交",
          content:
            "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为实现数字经济新农业，乡村振兴新载体发展起到强大支撑和赋能，同时发挥资产数字化拍卖平台的优势，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。",
          image: require('../../../static/newImage/jiaoyi.jpg'),
          bgImage: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/0afb480e81c24b2caa8215e7523ca054.png"
        },

      ],
      developmentIndex: 0,
      newsList: [],
      newsList1: [],
      newsList2: [],
      newsList3: [],
      newsList4: [],
      newsList5: [],
      newsList6: [],
      newsImageIndex: 0,
      playAm: false,
      playAmIndex: 0,
      doNothing: 0
    };
  },
  filters: {
    enterText(text) {
      const textArr = text.split('')
      return textArr.join('/n')
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    if (this.$route.query.id) {
      const id = this.$route.query.id
      const index = this.list.findIndex((item) => { return item.id == id })
      this.developmentIndex = index
      this.listName.splice(5, 1)
      const clickArr = this.listName.splice(index, this.listName.length - 1)
      const beforeArr = this.listName.splice(0, index)
      const newArr = clickArr.concat(beforeArr)
      this.listName = [newArr[0], newArr[1], newArr[2], newArr[3], newArr[4], newArr[5], newArr[5], newArr[6], newArr[7], newArr[8], newArr[9]]
    }
    if (this.$refs.newsImgCarousel) {
      this.slideBanner()
    }
    this.changeNewsIndex()
    this.getWXInfo()
  },
  beforeDestory() {
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪控股产业', // 分享标题
            desc: '立民族志气 创世界品牌', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/7174b29954a34f7b9c22cc0ad7714434.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    roundMap(index) {
      if (index !== 0) {
        this.playAm = true
        setTimeout(() => {
          this.playAm = false
          this.playAmIndex = index
          const name = this.listName[index]
          const indexChoose = this.list.findIndex((val) => val.title == name)
          this.developmentIndex = indexChoose
          if (this.developmentIndex == 0 || this.developmentIndex == 9) {
            this.changeNewsIndex()
          }
          if (index > 5) {
            index = index - 1
          }
          this.listName.splice(5, 1)
          const clickArr = this.listName.splice(index, this.listName.length - 1)
          const beforeArr = this.listName.splice(0, index)
          const newArr = clickArr.concat(beforeArr)
          this.listName = [newArr[0], newArr[1], newArr[2], newArr[3], newArr[4], newArr[5], newArr[5], newArr[6], newArr[7], newArr[8], newArr[9]]
        }, 200);
      }
    },
    toDetails(item) {
      if (this.developmentIndex == 9) {
        window.open(`https://sj.zoneyu.net/newsListDetail?id=${item.newsId}&type=新闻资讯`);
      }
      if (this.developmentIndex == 0) {
        window.open(`https://www.zoneyu.cc/#/newsListDetail?id=${item.newsId}`);
      }

    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.newsImgCarousel .el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.newsImgCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.newsImgCarousel.prev();
          return;
        }
      });
    },
    changeNewsImgCarousel(index) {
      this.newsImageIndex = index;
    },
    changeNewsImageIndex(index) {
      this.newsImageIndex = index;
      this.$refs.newsImgCarousel.setActiveItem(index);
    },
    changeNewsIndex() {
      const id = this.list[this.developmentIndex].id
      let websiteCategoryId;
      if (id == 2) {
        websiteCategoryId = 9
      } else if (id == 6) {
        websiteCategoryId = 18
      }
      this.newsList1 = []
      this.newsList2 = []
      this.newsList3 = []
      this.newsList4 = []
      this.newsList5 = []
      this.newsList6 = []
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=50&websiteCategoryId=` + websiteCategoryId
      ).then(async (res) => {
        if (res.data.data) {
          this.newsList = res.data.data.list.slice(0, 4);
          this.newsList1 = res.data.data.list.slice(5, 8);
          this.newsList2 = res.data.data.list.slice(9, 12);
          this.newsList3 = res.data.data.list.slice(13, 16);
          if (res.data.data.total > 19) {
            this.newsList4 = res.data.data.list.slice(17, 20);
            this.newsList5 = res.data.data.list.slice(21, 24);
            this.newsList6 = res.data.data.list.slice(25, 28);
          }
          new Swiper('.swiper-container1', {
            loop: true,
            observer: true,  //开启动态检查器，监测swiper和slide
            observeParents: true,  //监测Swiper 的祖/父元素
            pagination: {
              el: '.swiper-pagination1',
            },
          });
          new Swiper('.swiper-container2', {
            loop: true,
            observer: true,  //开启动态检查器，监测swiper和slide
            observeParents: true,  //监测Swiper 的祖/父元素
            pagination: {
              el: '.swiper-pagination2',
            },
          });
          new Swiper('.swiper-container3', {
            loop: true,
            observer: true,  //开启动态检查器，监测swiper和slide
            observeParents: true,  //监测Swiper 的祖/父元素
            pagination: {
              el: '.swiper-pagination3',
            },
          });
          new Swiper('.swiper-container4', {
            loop: true,
            observer: true,  //开启动态检查器，监测swiper和slide
            observeParents: true,  //监测Swiper 的祖/父元素
            pagination: {
              el: '.swiper-pagination4',
            },
          });
          new Swiper('.swiper-container5', {
            loop: true,
            observer: true,  //开启动态检查器，监测swiper和slide
            observeParents: true,  //监测Swiper 的祖/父元素
            pagination: {
              el: '.swiper-pagination5',
            },
          });
          new Swiper('.swiper-container6', {
            loop: true,
            observer: true,  //开启动态检查器，监测swiper和slide
            observeParents: true,  //监测Swiper 的祖/父元素
            pagination: {
              el: '.swiper-pagination6',
            },
          });
        }
      });
    },
    toHomePath() {
      const id = this.list[this.developmentIndex].id
      if (id == 2) {
        window.open("https://sj.zoneyu.net/");
      } else if (id == 6) {
        window.open("https://www.zoneyu.cc/");
      } else if (id == 7) {
        window.open("https://www.zoneyu.com.cn/");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.barnner {
  background-image: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/e68c9fa50e324fbd9f2e1f825c62f143.png");
  width: 100%;
  height: 100vw/(1920/620);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
  z-index: 99;

  img {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.development_map {
  background-image: url("../../../static/newImage/development_bg_map.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1052px;
  transform: translate(0, -125px);
  z-index: 9;
  position: relative;

  .development_map_img {

    padding-top: 120px;
    margin: 0 auto;
    width: 1200px;
    text-align: center;
  }

  .development_map_choose {
    margin: 50px auto;
    width: 404px;
    height: 173px;
    background-image: url('../../../static/newImage/development_choose1.png');
    background-repeat: no-repeat;
    background-size: contain;
    transform: scale(2);
  }

  .development_map_choose_img {
    margin: 0 auto;
    width: 46px;
    transform: translate(0, -155px);
  }

  .development_map_line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1400px;
    height: 800px;
    text-align: center;

    .development_map_line_1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -35%);
      width: 100%;
      height: 500px;
      border: 2px dashed #A4917A;
      opacity: 0.15;
      border-radius: 50%;
    }

    .development_map_line_2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -30%);
      width: 100%;
      height: 500px;
      border: 2px dashed #A4917A;
      border-radius: 50%;
    }

    .development_map_line_top {
      position: absolute;
      top: 22.5%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      background-color: transparent
    }

    .development_map_line_container1 {
      padding-top: 70px;
      height: 600px;
      width: 100%;
      perspective: 1200px;
      perspective-origin: center -200px;

      .earth-container {
        position: relative;
        width: 100%;
        height: 100%;
        animation: earth-rotation 1s linear infinite;
        transform-style: preserve-3d;
        transform-origin: center;

        .card {
          position: absolute;
          width: 100px;
          height: 147px;
          line-height: 100px;
          text-align: center;
          font-size: 16px;
          border-radius: 10px;
          transform-origin: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background-size: cover;
          background-repeat: no-repeat;
        }

        .card1 {
          transform: rotateY(0deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_yl1.png');
        }

        .card2 {
          transform: rotateY(36deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_ry1.png');
        }

        .card3 {
          transform: rotateY(72deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_xx1.png');
        }

        .card4 {
          transform: rotateY(108deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_tz1.png');
        }

        .card5 {
          transform: rotateY(144deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_sm1.png');
        }

        .card6 {
          transform: rotateY(180deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_ny1.png');
        }

        .card7 {
          transform: rotateY(216deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_qd1.png');
        }

        .card8 {
          transform: rotateY(252deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_yy1.png');
        }

        .card9 {
          transform: rotateY(288deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_zy1.png');
        }

        .card10 {
          transform: rotateY(324deg) translateZ(520px);
          background-image: url('../../../static/newImage/development_sj1.png');
        }
      }

      .earth-container1 {
        position: relative;
        width: 100%;
        height: 100%;

        .card {
          cursor: pointer;
        }

        .card1 {
          position: absolute;
          left: 43%;
          top: 95%;
          width: 180px;
          height: 291px;
          background: url('../../../static/newImage/development_map_bg.png') no-repeat;
          background-size: cover;
        }

        .card2 {
          position: absolute;
          left: 23%;
          top: 92%;
          width: 176px;
          height: 262px;
          background: url('../../../static/newImage/development_left1.png') no-repeat;
          background-size: cover;
        }


        .card9 {
          position: absolute;
          right: 23%;
          top: 92%;
          width: 176px;
          height: 262px;
          background: url('../../../static/newImage/development_right1.png') no-repeat;
          background-size: cover;
        }

        .card3 {
          position: absolute;
          left: 6%;
          top: 75%;
          width: 125px;
          height: 244px;
          background: url('../../../static/newImage/development_right5.png') no-repeat;
          background-size: cover;
        }

        .card8 {
          position: absolute;
          right: 6%;
          top: 75%;
          width: 125px;
          height: 244px;
          background: url('../../../static/newImage/development_left5.png') no-repeat;
          background-size: cover;
        }

        .card4 {
          position: absolute;
          left: -2%;
          top: 57%;
          width: 52px;
          height: 237px;
          background: url('../../../static/newImage/development_left3.png') no-repeat;
          background-size: cover;
        }

        .card7 {
          position: absolute;
          right: -2%;
          top: 57%;
          width: 52px;
          height: 237px;
          background: url('../../../static/newImage/development_right3.png') no-repeat;
          background-size: cover;
        }

        .card5 {
          position: absolute;
          left: 5%;
          top: 34%;
          width: 62px;
          height: 149px;
          background: url('../../../static/newImage/development_left4.png') no-repeat;
          background-size: cover;
        }

        .card6 {
          position: absolute;
          right: 5%;
          top: 34%;
          width: 62px;
          height: 149px;
          background: url('../../../static/newImage/development_right4.png') no-repeat;
          background-size: cover;
        }

        .card10 {
          position: absolute;
          left: 16%;
          top: 29%;
          width: 60px;
          height: 102px;
          background: url('../../../static/newImage/development_right2.png') no-repeat;
          background-size: cover;
        }

        .card11 {
          position: absolute;
          right: 16%;
          top: 29%;
          width: 60px;
          height: 102px;
          background: url('../../../static/newImage/development_left2.png') no-repeat;
          background-size: cover;
        }
      }

      @keyframes earth-rotation {
        from {
          transform: rotateY(0deg);
        }

        to {
          transform: rotateY(360deg);
        }
      }
    }

    .development_map_text {
      z-index: 98;
      font-weight: bold;
      font-size: 28px;
      color: #8B796A;
      line-height: 29px;
      letter-spacing: 2px;
      cursor: pointer;

      .development_map_text {
        writing-mode: vertical-lr;
        /* 从右到左 */
        text-orientation: upright;
        /* 保持正常显示 */
      }

      .development_map_text_0 {
        position: absolute;
        left: 43%;
        top: 85%;
        height: 226px;
        width: 178px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 31px;
        color: #FFEED9;
        line-height: 34px;
        letter-spacing: 3px;
      }

      .development_map_text_1,
      .development_map_text_10 {
        position: absolute;
        right: 23%;
        top: 82%;
        width: 176px;
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 40px;
      }

      .development_map_text_10 {
        left: 23%;
      }

      .development_map_text_2,
      .development_map_text_9 {
        position: absolute;
        font-size: 21px;
        right: 4%;
        top: 72%;
        width: 175px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 25px;
      }

      .development_map_text_9 {
        left: 4%;
      }

      .development_map_text_3,
      .development_map_text_8 {
        position: absolute;
        font-size: 14px;
        right: -6.5%;
        top: 57%;
        width: 175px;
        height: 140px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .development_map_text_8 {
        left: -6.5%;
      }

      .development_map_text_4,
      .development_map_text_7 {
        position: absolute;
        font-size: 12px;
        right: 1%;
        top: 37.5%;
        width: 175px;
        height: 100px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(.8);
      }

      .development_map_text_7 {
        left: 1%;
      }

      .development_map_text_5,
      .development_map_text_6 {
        position: absolute;
        font-size: 12px;
        right: 12%;
        top: 31%;
        width: 175px;
        height: 100px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(.8);
      }

      .development_map_text_6 {
        left: 12%;
      }
    }
  }
}

@media screen and (max-device-width: 600px) {
  .barnner {
    height: 620px;
  }

  .development_map_line {
    transform: translate(-50%, -50%) scale(.8) !important;
  }

  .development_map_img {
    transform: scale(.8) !important;
  }

  .development_map_choose {
    margin: 0 auto !important;
    transform: translate(0, -40px) scale(1.2);
  }
}

.development_bg {
  width: 1200px;
  padding: 0 20px;
  height: 500px;
  margin: 70px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  .development_bg_title {
    font-family: 楷体;
    font-weight: bold;
    font-size: 59px;
    color: #FFFFFF;
    line-height: 53px;
    text-align: left;
    font-style: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }
}

.development_bg::before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #00000069;
}



.development_intro {
  padding-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 50%;
  width: 100%;

  .development_intro_title {
    text-align: center;
    font-weight: bold;
    font-size: 50px;
    color: #000000;
    font-style: normal;
  }

  .development_intro_content {
    font-weight: 500;
    font-size: 32px;
    color: #000000;
    line-height: 61px;
    font-style: normal;
    width: 1200px;
    padding: 0 20px;
    margin: 60px auto 0 auto;
  }

  .development_news_bg {
    width: 1200px;
    padding: 0 20px;
    margin: 40px auto;
    position: relative;

    .development_news_bg_bottom {
      position: absolute;
      bottom: 0;
      left: 20px;
      z-index: 2;
      color: #fff;
      padding: 0 16px;
      font-weight: bold;
      width: calc(100% - 72px);
      height: 242px;
      line-height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: flex-end;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

      .development_news_bg_bottom_title {
        cursor: pointer;
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 24px;
      }

      .development_news_bg_bottom_Index {
        float: left;
        color: #354A32;
        width: 20px;
        height: 18px;
        position: relative;
        line-height: 18px;
        border: 1px solid #FFFFFF;
        text-align: center;
        margin-right: 5px;
        cursor: pointer;
        background: #ffffff9a;
      }

      .development_news_bg_bottom_Index:hover {
        color: #fff;
        background: #354A32;
        border: 1px solid #354A32;
      }

      .active {
        color: #fff;
        background: #354A32;
        border: 1px solid #FFFFFF;
      }
    }

    .newsImgCarousel {
      .newsImgCarousel_black {
        width: 1200px;
        height: 520px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
      }
    }
  }

  .home_first_about {
    margin: 40px auto 0 auto;
    width: 1200px;
    padding: 0 20px;
    display: flex;

    .home_first_about_img {
      overflow: hidden;
      margin-left: 15px;
      width: 416px;

      img {
        display: block;
        cursor: pointer;
      }

      .home_first_about_img_list {
        background-color: #fff;
        border: 1px solid #ddd;
        border-top: 0;
        line-height: 30px;
        padding: 10px 24px;

        .home_first_about_img_list_name {
          font-size: 14px;
          position: relative;
          padding-left: 20px;
          color: #333;
          white-space: nowrap;
          /* 防止文本换行 */
          overflow: hidden;
          /* 控制文本溢出时隐藏超出部分 */
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .home_first_about_img_list_name:last-child {
          border: none;
        }

        .home_first_about_img_list_name::before {
          content: "";
          background-color: #000;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 12px;
        }
      }
    }

    .home_first_about_img:first-child {
      margin-left: 0;
    }
  }
}

.development_more {
  padding: 45px 0;

  .development_more_btn {
    cursor: pointer;
    margin: 0 auto;
    width: 320px;
    height: 108px;
    background: #1A2E51;
    border-radius: 70px;
    font-weight: 500;
    font-size: 28px;
    color: #FFFFFF;
    line-height: 108px;
    text-align: center;
    font-style: normal;
  }
}

.swiper-container {
  --swiper-theme-color: #e11515;
}
</style>
